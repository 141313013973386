//LIBS
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";

//REDUCERS
import { changeUser } from "../../core/reducers/UserReducer/actions";

//STYLES
import { ProfileStyle } from "./styles";

//COMPONENTS
import ImageSelector from "components/ImageSelector";
import Button from "components/Button";

//TYPES
import { UserState } from "core/types/Types";

//UTILS
import { formatDate } from "core/utils/formatDate";
import notify from "core/utils/notify";
import { updateUser } from "core/utils/Api";
import { formDataUser } from "core/utils/transformData";
import { currencyFormat } from "core/utils/currencyFormat";
import Title from "components/Title";

interface IProfile {
  user: UserState;
  changeUser: () => void;
}

const Profile = ({ user, changeUser }: IProfile) => {
  const [imageUser, setImageUser] = useState<string>("");
  const [imageUpload, setImageUpload] = useState<any | null>(null);

  useEffect(() => {
    if (
      user &&
      user.photo !== null &&
      user.photo !== undefined &&
      imageUser === ""
    ) {
      const userPhoto: string = user.photo;
      const imageUrl =
        "https://api.optimus.mdsystemweb.com.br/" +
        userPhoto.split("/api/public")[1];
      setImageUser(imageUrl);
    }
  }, [user]);

  const fetchImage = async () => {
    if (imageUpload !== null) {
      try {
        const req = await updateUser(user.id, formDataUser(user, imageUpload));
        changeUser();

        setImageUser(req.data.photo);

        notify("success", "Imagem alterada com sucesso");
      } catch (err) {
        notify("error", "Erro ao enviar sua imagem");
      }
    }
  };

  const userInfos = [
    [
      { label: "Nome", value: user.firstname },
      { label: "Nascimento", value: formatDate(user.birthday) },
      { label: "RG", value: user.general_record },
      { label: "CPF", value: user.taxvat_primary },
      { label: "Contato", value: user.contact },
      { label: "E-mail Pessoal", value: user.email_guys },
    ],
    [
      { label: "E-mail Operador", value: user.email_operator },
      { label: "Agência", value: user.bank_agency },
      { label: "Conta", value: user.bank_account },
      { label: "Operação", value: user.bank_operation },
      { label: "Banco", value: user.bank },
      { label: "PIX", value: user.pix },
    ],
    [
      {
        label: "Data Inicio",
        value: formatDate(user.date_init),
      },
      { label: "Total Horas", value: user.amount_time },
      { label: "Cargo", value: user.position?.name || "Admin" },
      { label: "Acordo", value: user.agreement?.name || "Admin" },
      {
        label: "Salário Base",
        value: currencyFormat(user.wage),
      },
    ],
  ];

  return (
    <ProfileStyle>
      <Title>Meus Dados</Title>
      <div className="profile-card">
        <div className="image-holder">
          <ImageSelector
            image={imageUser}
            onChangeImg={(img) => {
              setImageUpload(img[0]);
              setImageUser(img[0]);
            }}
          />
          <Button color="green" onClick={fetchImage}>
            Salvar Alterações
          </Button>
        </div>
        <div className="infos-holder">
          {userInfos.map((infos, index) => (
            <div className="infos" key={index}>
              {infos.map((info) => (
                <p key={info.label}>
                  <span>{info.label}:</span> {info.value}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </ProfileStyle>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      changeUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
