// LIBS
import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import InputMask from "react-input-mask";

// API
import { addCustomers, getCustomers, updateCustomers } from "core/utils/Api";

//UTILS
import notify from "core/utils/notify";

// COMPONENTS
import Input from "components/Input";
import Select from "components/Select";
import Button from "components/Button";
import Table from "components/Table";
import Pagination from "components/Pagination";
import { RegisterUserStyle } from "container/Users/RegisterUser/styles";

interface Customer {
  name: string;
  last_name: string;
  zipcode: string;
  address: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  phone: string;
  email: string;
  password: string;
  is_active: boolean;
}

interface CepResponse {
  data: {
    cep: string;
    logradouro: string;
    complemento: string;
    bairro: string;
    localidade: string;
    state: string;
    uf: string;
    ibge: string;
    gia: string;
    ddd: string;
    siafi: string;
  };
}

const EMPTY_INPUTS: Customer = {
  name: "",
  last_name: "",
  zipcode: "",
  address: "",
  number: "",
  neighborhood: "",
  city: "",
  state: "",
  phone: "",
  email: "",
  password: "",
  is_active: true,
};

const headersArray = [
  "Nome",
  "Sobrenome",
  "CEP",
  "Endereço",
  "N°",
  "Bairro",
  "Cidade",
  "Estado",
  "Telefone",
  "Email",
  "Status",
];

const CustomersContainer = () => {
  const [inputsValues, setInputsValues] = useState<Customer>(EMPTY_INPUTS);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [customerToEdit, setCustomerToEdit] = useState<any>(null);
  const [registerStage, setRegisterStage] = useState<number>(1);

  useEffect(() => {
    document.querySelectorAll(`.column-${registerStage}`).forEach((el) => {
      el.classList.add("active");
    });

    return () => {
      document.querySelectorAll(`.column-${registerStage}`).forEach((el) => {
        el.classList.remove("active");
      });
    };
  }, [registerStage]);

  // GET CUSTOMERS
  const getCustomersData = async () => {
    const req = await getCustomers();

    if (req.error.length === 0) {
      setCustomers(req.data);
    } else {
      notify("error", req.error);
    }
  };

  useEffect(() => {
    getCustomersData();
  }, []);

  // ADD CUSTOMER
  const addCustomer = async () => {
    const req = await addCustomers(inputsValues);

    if (req.error && req.error.title) {
      notify("error", "error ao salvar cliente");
    } else {
      notify("success", "cliente salvo com sucesso");
      onSend();
      setInputsValues(EMPTY_INPUTS);
    }
  };

  // EDIT CUSTOMER
  const editCustomer = async () => {
    const req = await updateCustomers(customerToEdit.id, inputsValues);

    if (req.error && req.error.title) {
      notify("error", "error ao editar cliente");
    } else {
      notify("success", "cliente editado com sucesso");
      onSend();
    }
  };

  // HANDLE CAMCEL
  const handleCancel = () => {
    setInputsValues(EMPTY_INPUTS);
    setCustomerToEdit(null);
  };

  // HANDLE CHANGE
  const handleChange = async (e: any) => {
    setInputsValues({ ...inputsValues, [e.target.name]: e.target.value });

    if (e.target.name === "zipcode") {
      const data = await fetchCEP(e.target.value);

      if (data) {
        const cepData = data.data;
        setInputsValues({
          ...inputsValues,
          zipcode: cepData.cep,
          address: cepData.logradouro,
          neighborhood: cepData.bairro,
          city: cepData.localidade,
          state: cepData.uf || "",
        });
      }
      console.log(data);
    }
  };

  // CEP
  const fetchCEP = async (cep: string): Promise<CepResponse | null> => {
    try {
      const response: AxiosResponse<CepResponse> = await axios.get(
        `https://api.optimus.mdsystemweb.com.br/api/viacep/${cep}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Erro ao buscar CEP");
    }
  };

  // HANDLE SUBMIT
  const handleSubmit = () => {
    if (
      Object.values(inputsValues).every(
        (value) => value !== "" && value !== null
      )
    ) {
      if (customerToEdit) {
        editCustomer();
      } else {
        addCustomer();
      }
    } else {
      notify("error", "Preencha todos os campos corretamente");
    }
  };

  // HANDLE SEND (onSend)
  const onSend = () => {
    setInputsValues(EMPTY_INPUTS);
    getCustomersData();
  };

  // HANDLE EDIT (onEdit)
  const onEdit = (item: any, index: number) => {
    const customer = customers[index];
    setInputsValues(customer);
    setCustomerToEdit(customer);
  };

  return (
    <>
      <RegisterUserStyle>
        <form>
          {/* Seção 1 */}
          <Input
            value={inputsValues.name}
            onChange={handleChange}
            className="column-1"
            label="Nome:"
            name="name"
            type="text"
            required
          />
          <Input
            value={inputsValues.last_name}
            onChange={handleChange}
            className="column-1"
            label="Sobrenome:"
            name="last_name"
            type="text"
            required
          />
          <InputMask
            value={inputsValues.phone}
            onChange={handleChange}
            className="column-1"
            mask={"(99)99999-9999"}
          >
            <Input label="Telefone" name="phone" type="text" required />
          </InputMask>
          <Input
            value={inputsValues.email}
            onChange={handleChange}
            className="column-1"
            label="E-mail"
            name="email"
            type="text"
            required
          />
          <Input
            value={inputsValues.password}
            onChange={handleChange}
            className="column-1"
            label="Senha"
            name="password"
            type="password"
            required
          />
          <Select
            className="column-1"
            label="Status"
            value={inputsValues.is_active ? "1" : "0"}
            onSelect={(value) =>
              setInputsValues((prev: any) => {
                return { ...prev, is_active: value === "1" };
              })
            }
            options={[
              { label: "Ativo", value: "1" },
              { label: "Inativo", value: "0" },
            ]}
          />

          {/* Seção 2 */}
          <InputMask
            onChange={handleChange}
            className="column-2"
            value={inputsValues.zipcode}
            mask="99999-999"
          >
            <Input label="CEP:" name="zipcode" type="text" required />
          </InputMask>
          <Input
            value={inputsValues.address}
            onChange={handleChange}
            className="column-2"
            label="Endereço"
            name="address"
            type="text"
            required
          />
          <Input
            value={inputsValues.number}
            onChange={handleChange}
            className="column-2"
            label="Numero"
            name="number"
            type="text"
            required
          />
          <Input
            value={inputsValues.neighborhood}
            onChange={handleChange}
            className="column-2"
            label="Bairro"
            name="neighborhood"
            type="text"
            required
          />
          <Input
            value={inputsValues.city}
            onChange={handleChange}
            className="column-2"
            label="Cidade"
            name="city"
            type="text"
            required
          />
          <Input
            value={inputsValues.state}
            onChange={handleChange}
            className="column-2"
            label="Estado"
            name="state"
            type="text"
            required
          />

          {/* Buttons*/}
          <div className="buttons-holder-mobile">
            {console.log(registerStage)}
            {registerStage && (
              <div className="col-2">
                <Button
                  color="light-blue"
                  onClick={() => {
                    {registerStage == 1 ? setRegisterStage(registerStage) : setRegisterStage(registerStage - 1)};
                  }}
                >
                  Voltar
                </Button>
                <Button
                  onClick={() => {
                    {registerStage == 2 ? setRegisterStage(registerStage) : setRegisterStage(registerStage + 1)};
                  }}
                >
                  Próximo
                </Button>
              </div>
            )}

            {registerStage == 2 && !customerToEdit && <Button onClick={handleSubmit}>Cadastrar</Button>}
            {customerToEdit && (
              <>
                <Button onClick={handleSubmit}>Salvar</Button>
                <Button color="danger" onClick={handleCancel}>
                  Cancelar
                </Button>
              </>
            )}
          </div>
        </form>
      </RegisterUserStyle>
      <div className="double-tables">
        <div className="tables-content">
          <Pagination>
            <div className="table-holder">
              {customers.length ? (
                <Table
                  onEdit={onEdit}
                  headerList={headersArray}
                  bodyList={customers.map((customer, index) => ({
                    id: index,
                    list: [
                      customer.name,
                      customer.last_name,
                      customer.zipcode,
                      customer.address,
                      customer.number,
                      customer.neighborhood,
                      customer.city,
                      customer.state,
                      customer.phone,
                      customer.email,
                      customer.is_active ? "Ativo" : "Inativo",
                    ],
                  }))}
                />
              ) : (
                <p>Nenhum cliente encontrado!</p>
              )}
            </div>
          </Pagination>
        </div>
      </div>
    </>
  );
};

export default CustomersContainer;
