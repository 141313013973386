//STYLES
import { TaskCardStyle } from "./style";
//TYPES
import { ITask } from "../../core/types/Types";
//LIBS
import { useNavigate } from "react-router-dom";
import notify from "../../core/utils/notify";
import { useState } from "react";
import { Draggable } from "@hello-pangea/dnd";
import sign from "jwt-encode";
import { FiClipboard, FiUserCheck, FiEdit, FiFileText } from "react-icons/fi";
//COMPONENTS
import Button from "../Button";
import { getTasks, assumeTask } from "../../core/utils/Api";

interface ITaskCard {
  task: ITask;
  index: number;
  onEditTask: (task: ITask) => void;
  openEdit: () => void;
  openView: (task: ITask) => void;
  openHist: (task: ITask) => void;
  appointPerTask?: boolean;
  count_assumed?: boolean
}

const TaskCard = ({
  task,
  index,
  onEditTask,
  openView,
  openHist,
  appointPerTask = false,
  count_assumed
}: ITaskCard) => {
  const navigate = useNavigate();
  const [isTaskAssumed, setIsTaskAssumed] = useState(task.isAssumed || false);

  function padNumber(num: number, n: number): string {
    const numStr = num.toString();
    return numStr.length < n ? "0".repeat(n - numStr.length) + numStr : numStr;
  }

  const formatCreationAndDeliveryDate = () => {
    return (
      <>
        {new Date(task.created_at).toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        })}{" "}
        -{" "}
        {new Date(task.delivery_date).toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        })}
      </>
    );
  };

  const formatName = () => {
    return task.name.split(" - ").length > 1
      ? task.name
        .split(" - ")
        .filter((value, index) => index !== 0)
        .join(" - ")
      : task.name;
  };

  const getTaskOnClick = async () => {
    try {
      let data = await assumeTask(task.id);

      notify("success", `Tarefa TK-${padNumber(task.code, 3)} ${data.data}`);
      setIsTaskAssumed(true);
      task.assumed_end = new Date().toISOString();
    } catch (error) {
      notify("error", "Erro ao assumir a tarefa.");
    }
  };


  return (
    <TaskCardStyle key={task.id}>
      <Draggable index={index} draggableId={`${sign(task, "task")}`}>
        {(provided) => (
          <div
            className="card"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="card--header">
              TK-{padNumber(task.code, 3)}
              {!count_assumed && <div className="new-icon"></div>}
            </div>
            <div className="card--body" onClick={() => openView(task)}>
              <>{formatName()}</>
              <p className="date">{formatCreationAndDeliveryDate()}</p>
            </div>
            <div className="card--footer">
              <span onClick={() => openHist(task)}>
                {Math.round(task.quantity_hours)}h
              </span>
              <div className="buttons-holder">
                {appointPerTask && (
                  <Button
                    size="s4"
                    color="light-blue"
                    onClick={() => navigate(`Timekeeping/${task.id}`)}
                  >
                    <FiClipboard />
                  </Button>
                )}
                <Button
                  size="s4"
                  color="default"
                  onClick={() => openHist(task)}
                >
                  <FiFileText />
                </Button>
                <Button size="s4" color="green" onClick={getTaskOnClick}>
                  <FiUserCheck />
                </Button>
                <Button
                  onClick={() => {
                    onEditTask(task);
                  }}
                  size="s4"
                  color="white"
                >
                  <FiEdit />
                </Button>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </TaskCardStyle>
  );
};

export default TaskCard;
