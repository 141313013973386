//LIBS
import { styled } from "styled-components";

//STYLESHETS
import { colors } from "../../core/stylesheet/includes";

export const TaskCardStyle = styled.div`

    &:last-child {
        margin-bottom: 60%;
    }

    .card {
        background-color: ${colors.mainBlue};
        max-width: 100%;
        width: 100%;
        border-radius: 10px;
        border: 1px solid ${colors.blackBlue};

        &:hover {
            cursor: grab;
        }

        &--header {
            border-radius: 8px 8px 0 0;
            display: flex;
            padding: 8px;
            align-items: center;
            gap: 8px;
            background-color: ${colors.mainBlue};
            color: ${colors.white};
            position: relative;

            div.new-icon {
                position: absolute;
                right: 10px;
                padding: 5px;
                border-radius: 50%;
                background: red;
                background: ${colors.lightBlue2};
            }
        }

        &--body {
            padding: 8px;
            background-color: ${colors.white};
            color: ${colors.black};
            display: flex;
            flex-direction: column;
            gap: 8px;

            .date {
                color: ${colors.mainBlue};
                font-family: Inter;
                font-size: 14px;
                font-weight: 600;
            }
        }

        &--footer {
            padding: 8px;
            background-color: ${colors.lightBlue};
            border-radius: 0 0 10px 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            span {
                color: ${colors.blackBlue};
                font-size: 16px;
                font-weight: 600;
            }

            .buttons-holder {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;
            }
        }
    }

    // .new-icon {
    //     back
    // }

    .content-container {
        &:nth-of-type(1) {
            max-width: 739px;
            width: 100%;
        }

        .modal-history-content {
            .history-content-collaborators {
                padding-bottom: 16px;
                margin-bottom: 16px;
                border-bottom: 1px solid ${colors.grayBlue};

                p {
                    font-size: 16px;
                    color: ${colors.darkBlueII};
                    font-weight: 500;

                    span {
                        font-size: 16px;
                        font-weight: 700;
                    }
                }
            }

            .history-content-list {
                margin-bottom: 16px;

                .history-list-title {
                    margin-bottom: 8px;

                    p {
                        font-size: 16px;
                        font-weight: 700;
                        color: ${colors.darkBlueII};
                    }
                }

                .history-list-cards {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .history-card {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 12px;
                        border-radius: 10px;
                        border: 1px solid ${colors.darkBlueII};

                        p {
                            font-size: 16px;
                            color: ${colors.darkBlueII};
                            font-weight: 500;

                            &:nth-of-type(1) {
                                width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
`;
