//STYLES
import { BoardsStyle } from "./style";

//COMPONENTS
import TaskCard from "../TaskCard";
import Button from "../Button";

//LIBS
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Droppable } from "@hello-pangea/dnd";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { FiPlus } from "react-icons/fi";

//TYPES
import { IBoardsHomeType, ITask, UserState } from "../../core/types/Types";

//API
import { getBoardById } from "../../core/utils/Api";

//UTILS
import notify from "../../core/utils/notify";

interface IBoard {
  board: IBoardsHomeType;
  index: number;
  updateLength: boolean;
  onCreateTask: (boardId: number, boardName: string) => void;
  onEditTask: (task: ITask) => void;
  openView: (task: ITask) => void;
  openHist: (task: ITask) => void;
  user: UserState;
}

const Board = ({
  board,
  index,
  updateLength,
  onCreateTask,
  onEditTask,
  openHist,
  openView,
  user,
}: IBoard) => {
  const [boardOpen, setBoardOpen] = useState<boolean>(true);
  const [taskLength, setTaskLength] = useState<number>(0);
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [page, setPage] = useState<number>(1);
  const [maxpage, setMaxPage] = useState<number>(0);
  const taskRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    const task = taskRef.current;

    if (task) {
      const isAtBottom =
        task.scrollTop + task.clientHeight === task.scrollHeight;

      if (isAtBottom) {
        setPage((prev) => {
          getTasksOnBoardOpen(prev + 1);
          return prev + 1;
        });
      }
    }
  };

  useEffect(() => {
    const task = taskRef.current;

    if (task) {
      task.addEventListener("scroll", handleScroll);

      return () => {
        task.removeEventListener("scroll", handleScroll);
      };
    }
  }, [taskRef.current]);

  useEffect(() => {
    if (typeof board.tasks_count === "number") setTaskLength(board.tasks_count);
    if (boardOpen) getTasksOnBoardOpen(1);
  }, [updateLength]);

  const getTasksOnBoardOpen = async (pageNumber = page) => {
    if (maxpage === 0 || pageNumber <= maxpage) {
      const req = await getBoardById(board.id, { page: pageNumber });

      const newTasks = req.data.tasks;

      if (req.error && req.error.title) {
        notify("error", req.error.title);
        return;
      }

      const isNewTask = (task: any) => {
        return task.frames_id === 2 && !task.isNew;
      };

      if (req.data && pageNumber > 1) {
        setTasks((prev) => [
          ...prev,
          ...newTasks.data.map((task: any) =>
            isNewTask(task) ? { ...task, isNew: true } : task
          ),
        ]);
      } else if (req.data && pageNumber === 1) {
        setTasks(
          newTasks.data.map((task: any) =>
            isNewTask(task) ? { ...task, isNew: true } : task
          )
        );
      }

      setMaxPage(newTasks.last_page);
    }
  };

  return (
    <>
      {board && (
        <BoardsStyle key={index}>
          <div
            className="board--header"
            onClick={() => {
              setBoardOpen(!boardOpen);
              getTasksOnBoardOpen();
            }}
          >
            <p>
              {board.name} - {taskLength}
            </p>
            {boardOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}
          </div>
          {boardOpen && (
            <Droppable
              droppableId={`${board.id}`}
              direction="vertical"
              type="list"
            >
              {(provided) => (
                <div
                  className="board--body"
                  ref={(el) => {
                    provided.innerRef(el);
                    if (el) taskRef.current = el;
                  }}
                  {...provided.droppableProps}
                >
                  {tasks.length ? (
                    tasks.map((task, index) => {
                      // console.log(task.assumed_end.length)

                      return (
                        <Fragment key={index}>
                          <TaskCard
                            openHist={openHist}
                            openView={openView}
                            openEdit={() => onEditTask(task)}
                            onEditTask={onEditTask}
                            index={index}
                            task={task}
                            appointPerTask={user.appointment_type_id === 1}
                            count_assumed={task.assumed_end.length}
                          />
                        </Fragment>
                      );
                    })
                  ) : (
                    <div className="no-task">Nenhuma tarefa encontrada</div>
                  )}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
          <div className="board--footer">
            <Button
              onClick={() => onCreateTask(board.id, board.name)}
              color="white"
              size="s2"
            >
              Adicionar Tarefa <FiPlus />
            </Button>
          </div>
        </BoardsStyle>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Board);
