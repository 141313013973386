//STYLES
import { HeaderStyle } from "./styles";

//LIBS
import { useLocation, useNavigate } from "react-router-dom";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import React, { useEffect, useRef, useState } from "react";
import * as FiIcons from "react-icons/fi";

//TYPES
import { IAbas } from "../../core/types/Types";

interface IHeaderItem {
    icon?: string | React.ReactElement;
    label: string;
    active?: boolean;
    to?: string;
    innerItems?: IAbas[];
    notifications?: number;
    onClick?: () => void;
    countTaskNotAssumeds?: number;
}

const HeaderItem = ({
    icon,
    label,
    active = false,
    to,
    innerItems,
    notifications,
    onClick,
    countTaskNotAssumeds
}: IHeaderItem) => {
    const [open, setOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const headerItem = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                headerItem.current &&
                !headerItem.current.contains(event.target as Node)
            ) {
                setOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <HeaderStyle
            ref={headerItem}
            className={active ? "active" : ""}
            onClick={() => {
                onClick && !innerItems && onClick();
                to && navigate(to);
                innerItems && setOpen(!open);
            }}
        >
            <div className="header-item">
                {typeof icon === "string"
                    ? React.createElement(FiIcons[icon as keyof typeof FiIcons])
                    : icon}
                {label == "Dashboard" &&
                    <span className="count-task-not-assumed">{countTaskNotAssumeds}</span>
                }

                <p>{label}</p>
                {innerItems && (open ? <TiArrowSortedUp /> : <TiArrowSortedDown />)}

                {!!notifications && <div className="notification">{notifications}</div>}
            </div>
            {open && innerItems && (
                <div ref={dropdownRef} className="header-dropdown" onClick={onClick}>
                    {innerItems.map((item, index) => (
                        <button
                            className={
                                item.link === location.pathname ||
                                    item.link === location.pathname.slice(1)
                                    ? "active"
                                    : ""
                            }
                            onClick={() =>
                                item.onClick ? item.onClick() : item.link && navigate(item.link)
                            }
                            key={index}
                        >
                            {item.name}
                        </button>
                    ))}
                </div>
            )}
        </HeaderStyle>
    );
};

export default HeaderItem;
