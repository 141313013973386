//LIB
import { DropResult } from "@hello-pangea/dnd";
import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { connect } from "react-redux";
import { FiClipboard } from "react-icons/fi";
import { bindActionCreators } from "redux";

// ACTIONs
import { setLoader } from "../../core/reducers/LoaderReducer/actions";

//STYLES
import { HomeStyle } from "./styles";

//CONTAINERS
import BoardsContainer from "../../container/Home/Boards";

//API
import {
  RegisterTask,
  getBoards,
  getTaskById,
  updateTask,
} from "../../core/utils/Api";

//TYPES
import { IBoardsHomeType, ITask, UserState } from "../../core/types/Types";

//UTILS
import notify from "../../core/utils/notify";

//COMPONENTS
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import FormTask from "../../components/FormTask";
import BoardDrawer from "../../components/BoardDrawer";
import Button from "../../components/Button";
import TaskModals from "container/Home/TaskModals";
import Title from "components/Title";

interface IHome {
  user: UserState;
  setLoader: (state: boolean) => void;
}

const Home = ({ user, setLoader }: IHome) => {
  const [boardsEnable, setBoardsEnable] = useState<IBoardsHomeType[]>([]);
  const [allBoards, setAllBoards] = useState<IBoardsHomeType[]>([]);
  const [updateLength, setUpdateLength] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [taskToEdit, setTaskToEdit] = useState<ITask | undefined>();
  const [taskToView, setTaskToView] = useState<ITask | undefined>();
  const [userData, setUserData] = useState<UserState>();
  const [openView, setOpenView] = useState<boolean>(false);
  const [openHist, setOpenHist] = useState<boolean>(false);
  const [taskBoard, setTaskBoard] = useState<{
    board?: number;
    nextTask?: number;
    name?: string;
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoader(true);
    getData();
    setUserData(user);

    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, []);

  const getData = async () => {
    const req = await getBoards();

    setBoardsEnable(req.data.enable);
    setAllBoards(req.data.all);
    setTaskBoard((prev) => {
      return { ...prev, nextTask: req.data.task_end };
    });
    setUpdateLength(!updateLength);
  };

  const onDragEnd = async (res: DropResult) => {
    if (res.destination && res.source) {
      let taskToChange = jwtDecode<ITask>(res.draggableId);

      if (+res.destination.droppableId === taskToChange.frames_id) return;

      const form = new FormData();
      form.append("frames_id", res.destination.droppableId);
      form.append("name", taskToChange.name);
      form.append("description", taskToChange.description);
      form.append("delivery_date", taskToChange.delivery_date);
      form.append("_method", "PUT");

      const req = await updateTask(taskToChange.id, form);

      setLoading(true);

      if (req.error) {
        // notify("error", req.error.title);
        getData();
      } else {
        // notify("success", req.data.title);
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const sendTask = async (data: { [key: string]: any }) => {
    setIsLoading(true);
    let req;

    const form = new FormData();
    form.append("frames_id", data.frames_id);
    form.append("name", data.name);
    form.append("description", data.description);
    form.append("delivery_date", data.delivery_date);
    form.append("quantity_hours", data.quantity_hours);
    Array.from(data.files ?? []).map((file: any) => {
      form.append("file[]", file);
    });

    // if (data.files) {
    //   Array.from(data.files).map((file: any) => {
    //     form.append("file[]", file);
    //   });
    // }

    if (taskToEdit) {
      form.append("_method", "PUT");
      req = await updateTask(taskToEdit.id, form);
    } else {
      req = await RegisterTask(form);
    }

    setIsLoading(false);

    if (req.error && req.error.title) {
      notify("error", req.error.title);
    } else {
      notify("success", req.data.title);
      getData();
      setModalOpen(false);
      setTaskToEdit(undefined);
      setIsLoading(false);
    }
  };

  return (
    <HomeStyle>
      {loading && <Loading />}
      <main>
        {userData && userData.group.name === "Master" && (
          <BoardDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            onSave={getData}
            boards={allBoards}
          />
        )}
        <div className="content-holder">
          <Title>Quadro de Tarefas</Title>
          {userData && userData.group.name === "Master" && (
            <div className="board-button">
              <Button onClick={() => setOpenDrawer(!openDrawer)} color="white">
                Quadros <FiClipboard />
              </Button>
            </div>
          )}
          <BoardsContainer
            openHist={(task) => {
              setOpenHist(true);
              setTaskToView(task);
            }}
            openView={(task) => {
              setOpenView(true);
              setTaskToView(task);
            }}
            onEditTask={async (data) => {
              const req = await getTaskById(data.id);
              setTaskToEdit(req.data);
              setModalOpen(true);
            }}
            updateBoard={updateLength}
            onDragEnd={onDragEnd}
            boards={boardsEnable}
            onCreateTask={(boardId, boardName) => {
              setModalOpen(true);
              setTaskBoard((prev) => {
                return { ...prev, board: boardId, name: boardName };
              });
            }}
          />
          {/* .. */}
          <Modal
            header={`${taskToEdit ? "Editar" : "Criar"} Tarefa - ${
              taskToEdit?.name.split(" - ")[0] || taskBoard.name
            }`}
            open={modalOpen}
            onClose={() => {
              setModalOpen(false);
              setTaskToEdit(undefined);
            }}
          >
            <FormTask
              taskToEdit={taskToEdit}
              frameId={taskBoard.board}
              resetData={modalOpen}
              isLoading={isLoading}
              taskCode={`${
                taskToEdit
                  ? `TK-${taskToEdit.code}`
                  : `TK-${taskBoard.nextTask}`
              }`}
              onClose={() => {
                setModalOpen(false);
                setTaskToEdit(undefined);
              }}
              onSave={sendTask}
            />
          </Modal>

          {taskToView && (
            <TaskModals
              historicOpen={openHist}
              modalOpen={openView}
              closeModals={() => {
                setOpenHist(false);
                setOpenView(false);
              }}
              task={taskToView}
              openEdit={() => {
                setTaskToEdit(taskToView);
                setModalOpen(true);
              }}
            />
          )}
        </div>
      </main>
    </HomeStyle>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setLoader,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
